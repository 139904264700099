import React  from "react";
import headerLogoSrc from "../../assets/img/logo.png";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { useOrderPopup } from "../../contexts/orderPopupContext";

const Header = () => {
   const {
      header,
      headerContainer,
      headerScheduleContainer,
      headerTelContainer,
      headerMenuNav,
      headerBtnPrice,
      menuNavigation,
   } = styles;
   const { setIsOpenPrice } = useOrderPopup();
   return (
      <header id="header"  className={header}>
         <div className={`container ${headerContainer}`}>
            <NavLink to={"/"}>
               <img
                  src={headerLogoSrc}
                  className="logo"
                  alt="Colibri клининговая компания"
               />
            </NavLink>
            <nav className={headerMenuNav}>
               <div className={headerScheduleContainer}>
                  <div></div>
                  <p>с 9:00 до 22:00</p>
               </div>
               <div className={headerTelContainer}>
                  <a href="https://vk.com/coli_cleaning">
                     <img src={'https://static.tildacdn.com/tild6333-3861-4362-b965-663038623337/449429d7ed748f6be09e.svg'} style={{maxWidth: "42px"}} alt="VK" />
                  </a>
                  <a href="https://wa.me/79167694745">
                     <img src={'https://static.tildacdn.com/tild6164-3137-4865-b165-633739316562/f40fba1c99e24aef1b81.svg'} style={{maxWidth: "42px"}} alt="WhatsApp" />
                  </a>
                  <a href="tel:+79167694745">
                     <img src={'https://thumb.tildacdn.com/tild3735-6332-4537-b236-643364643832/-/resize/50x/-/format/webp/_.png'} alt="Звонок" />
                  </a>
                  <a href="tel:+79167694745">+7 (916) 769-47-45</a>
                  <button
                     onClick={() => {
                        document.body.style.overflow = "hidden";
                        setIsOpenPrice(true);
                     }}
                     className={headerBtnPrice}
                  >
                     Узнать стоимость
                  </button>
               </div>
            </nav>
         </div>
         <div className={menuNavigation}>
            <NavLink to={"/cleaning"}>Уборка</NavLink>
            <NavLink to={"/dryCleaning"}>Химчистка</NavLink>
            <NavLink to={"/forBusiness"}>Для бизнеса</NavLink>
         </div>
      </header>
   );
};

export default Header;
