import React from "react";
import styles from "./SuccessPage.module.css";

const SuccessPage = () => {
   const {
      mainContainer
   } = styles;
   return (
      <>
         <section className={mainContainer}>
            <center><h1>Спасибо!</h1>
            <p>Мы перезвоним Вам в течении 20 минут для уточнения деталей.</p>
            </center>
         </section>
      </>
   );
};

export default SuccessPage;
